<script>
	import Card from '$components/Card.svelte';
	// import Hero from '$scenes/Hero.svelte';
	import { onMount } from 'svelte';
	import { beforeNavigate } from '$app/navigation';
	import { heroActive, workList } from '$routes/stores';

	let isOdd = false;
	let isEven = true;

	if (workList.length % 2 === 1) {
		isOdd = true;
		isEven = false;
	};

	function scrollToWork() {
		const target = document.getElementById("WORK-PAD");
		target.scrollIntoView({ behavior: "smooth" });
	};

	let intersectionTarget, observer;
	function observerFunc(targets) {
		if (pageNavCondition) return
		targets.forEach(target => {
			if (target.isIntersecting) {
				heroActive.set(true)
			} else {
				heroActive.set(false);
			}
		})
	};

	const options = {
		root: null,
		rootMargin: '0px',
		threshold: 0.01
	};

	let pageNavCondition = false;
	beforeNavigate(() => {
		pageNavCondition = true;
	});

	onMount(async () => {
		intersectionTarget = document.getElementById("TOP-PAD");
		observer = new IntersectionObserver(observerFunc, options);
		observer.observe(intersectionTarget)

		return () => {
			observer.disconnect();
		}
	})
</script>

<svelte:head>
	<title>Alex Brad — Home</title>
	<meta name="description" content="Senior UX/UI designer with background in psychology." />

	<meta property="og:title" content="Alex Brad — Senior designer">
	<meta property="og:description" content="Senior designer / aspiring coder; with background in psychology.">
	<meta property="og:url" content="https://www.alexbrad.com">

	<meta name="twitter:title" content="Alex Brad — Senior designer" />
	<meta property="twitter:description" content="Senior designer / aspiring coder; with background in psychology.">
	<meta property="twitter:site" content="https://www.alexbrad.com">
</svelte:head>

<section id="TOP-PAD">
	<div class="section-wrapper header">
		<!-- <div class="banner">
			<div class="error">
				<p>Scene under development</p>
			</div>
			<Hero />
		</div> -->
		<div class="banner-content">
			<div class="banner-text">
				<h1>Alex Brad</h1>
				<p class="body1" style="color: var(--neutral-700)">Senior product designer / aspiring coder; with background in psychology</p>
			</div>
			<!-- <div class="action">
				<button class="interactive"
					on:click={scrollToWork}
				>
					<span class="typography">See my work</span>
					<svg class="interactive-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10.5029 4.25C10.2267 4.25 10.0029 4.47386 10.0029 4.75L10.0029 16.75C10.0029 17.0261 10.2267 17.25 10.5029 17.25H13.5029C13.779 17.25 14.0029 17.0261 14.0029 16.75V4.75C14.0029 4.47386 13.779 4.25 13.5029 4.25L10.5029 4.25Z" fill="currentColor"/>
						<path d="M3.85641 11.5576C3.66115 11.7528 3.66115 12.0694 3.85641 12.2647L11.6346 20.0429C11.8298 20.2381 12.1464 20.2381 12.3417 20.0429L14.463 17.9215C14.6583 17.7263 14.6583 17.4097 14.463 17.2144L6.68483 9.43626C6.48957 9.241 6.17299 9.241 5.97773 9.43626L3.85641 11.5576Z" fill="currentColor"/>
						<path d="M11.6493 20.0429C11.8446 20.2382 12.1611 20.2382 12.3564 20.0429L20.1346 12.2648C20.3298 12.0695 20.3298 11.7529 20.1346 11.5576L18.0133 9.43633C17.818 9.24107 17.5014 9.24107 17.3062 9.43633L9.52798 17.2145C9.33272 17.4098 9.33272 17.7263 9.52798 17.9216L11.6493 20.0429Z" fill="currentColor"/>
					</svg>
				</button>
			</div> -->
		</div>
	</div>
</section>

<section id="WORK-PAD">
	<div class="section-wrapper">
		<div class="card-grid">
			<div class="spacer"></div>
			<!-- <div class="card-wrapper">
				<Card object={ workList[3] }/>
			</div> -->
			<!-- <div class="card-wrapper">
				<Card object={ workList[2] }/>
			</div> -->
			<!-- <div class="card-wrapper">
				<Card object={ workList[1] }/>
			</div> -->
			<div class="card-wrapper">
				<Card object={ workList[0] }/>
			</div>
			<div class="card-wrapper">
				<div class="empty-card">
					<p>More projects coming soon</p>
				</div>
			</div>
			<div class="grid-text" class:odd={ isOdd }>
				<a href="/about"
					class="interactive"
					aria-label="About me"
				>
					{#if isOdd}
						<svg class="interactive-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M19.7494 10.5079C19.7494 10.2317 19.5256 10.0079 19.2494 10.0079H7.24944C6.97329 10.0079 6.74944 10.2317 6.74944 10.5079V13.5079C6.74944 13.784 6.97329 14.0079 7.24944 14.0079H19.2494C19.5256 14.0079 19.7494 13.784 19.7494 13.5079V10.5079Z" fill="currentColor"/>
							<path d="M12.4419 3.86141C12.2466 3.66615 11.93 3.66615 11.7347 3.86141L3.95658 11.6396C3.76131 11.8348 3.76131 12.1514 3.95658 12.3467L6.0779 14.468C6.27316 14.6633 6.58974 14.6633 6.785 14.468L14.5632 6.68984C14.7584 6.49458 14.7584 6.17799 14.5632 5.98273L12.4419 3.86141Z" fill="currentColor"/>
							<path d="M3.95651 11.6543C3.76124 11.8496 3.76124 12.1662 3.95651 12.3614L11.7347 20.1396C11.9299 20.3348 12.2465 20.3348 12.4418 20.1396L14.5631 18.0183C14.7584 17.823 14.7584 17.5064 14.5631 17.3112L6.78493 9.53299C6.58967 9.33772 6.27309 9.33772 6.07783 9.53299L3.95651 11.6543Z" fill="currentColor"/>
						</svg>
					{/if}
					<span class="typography">More about me</span>
					{#if isEven}
						<svg class="interactive-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M4.25 10.5079C4.25 10.2317 4.47386 10.0079 4.75 10.0079H16.75C17.0261 10.0079 17.25 10.2317 17.25 10.5079V13.5079C17.25 13.784 17.0261 14.0079 16.75 14.0079H4.75C4.47386 14.0079 4.25 13.784 4.25 13.5079V10.5079Z" fill="currentColor"/>
							<path d="M11.5576 3.86141C11.7528 3.66615 12.0694 3.66615 12.2647 3.86141L20.0429 11.6396C20.2381 11.8348 20.2381 12.1514 20.0429 12.3467L17.9215 14.468C17.7263 14.6633 17.4097 14.6633 17.2144 14.468L9.43626 6.68984C9.241 6.49458 9.241 6.17799 9.43626 5.98273L11.5576 3.86141Z" fill="currentColor"/>
							<path d="M20.0429 11.6543C20.2382 11.8496 20.2382 12.1662 20.0429 12.3614L12.2648 20.1396C12.0695 20.3348 11.7529 20.3348 11.5576 20.1396L9.43633 18.0183C9.24107 17.823 9.24107 17.5064 9.43633 17.3112L17.2145 9.53299C17.4098 9.33772 17.7263 9.33772 17.9216 9.53299L20.0429 11.6543Z" fill="currentColor"/>
						</svg>
					{/if}
				</a>
			</div>
		</div>
	</div>
</section>

<style>
	section {
		color: var(--neutral-900);
	}

	.banner-content {
		display: flex;
		justify-content: space-between;
		align-items: start;
		width: 100%;
		padding: var(--size-11);
	}

	.banner-text {
		width: 50%;
	}

	.action {
		display: flex;
		align-items: center;
		min-width: min-content;
		height: calc(var(--size-14) * 1.2);
		margin-left: var(--size-11);
	}

	button {
		padding: var(--size-7);
		font-family: Sora;
		font-size: var(--size-10);
		font-weight: 600;
		line-height: 130%;
		text-wrap: nowrap;
		border-radius: var(--size-11);
	}

	a {
		padding: var(--size-7);
		margin: calc(var(--size-7) * -1);
		font-family: Urbanist;
		font-size: var(--size-9);
		font-weight: 600;
		line-height: 130%;
		text-wrap: nowrap;
		border-radius: var(--size-11);
	}

	.interactive {
		color: var(--neutral-900);
	}

	.interactive:hover {
		background-color: var(--neutral-200);
	}

	.interactive:active {
		background-color: var(--neutral-300);
	}

	.interactive-icon {
		width: var(--size-10);
		height: var(--size-10);
	}

	.card-grid {
		display: grid;
		grid-auto-rows: 1fr;
		grid-template-columns: 1fr 1fr;
		gap: var(--size-11);
		width: 100%;
	}

	.spacer {
		grid-row: auto / 1;
		display: block;
	}
	
	.grid-text {
		display: flex;
		flex-direction: column;
		justify-content: end;
		align-items: end;
		grid-row: auto / span 2;
		padding: var(--size-11);
	}

	.grid-text.odd {
		align-items: start;
	}

	.card-wrapper {
		grid-row: auto / span 3;
		grid-column: 1fr;
		height: 100%;
	}


	@media only screen and (max-width: 989px) {
		.card-grid {
			gap: var(--size-9);
		}

		.card-wrapper {
			grid-row: auto / span 2;
		}
	}

	@media only screen and (max-width: 599px) {
		.banner-text {
			width: 100%;
		}

		.action {
			display: none;
		}
	}

	@media only screen and (max-width: 479px) {
		.banner-content {
			display: flex;
			flex-direction: column;
		}

		.action {
			display: block;
			margin: var(--size-9) 0 0 calc(var(--size-8) * -1);
		}

		.card-grid {
			grid-template-columns: 1fr;
			gap: var(--size-11);
		}

		.spacer {
			display: none;
		}

		.card-wrapper {
			grid-row: auto / span 3;
		}
	}

	.empty-card {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: var(--size-11);
		aspect-ratio: 1 / 1.2;
		overflow: hidden;
		color: var(--neutral-900-pure);
		background-color: var(--highlight-tangerine);
		border-radius: var(--size-11);
		outline: 0 solid transparent;
	}

	.empty-card p {
		width: 50%;
		text-align: center;
		font-family: Sora;
		font-size: var(--size-10);
		font-weight: 600;
		line-height: 140%;
	}
</style>