<script>
    let hover = false;
    export let object;

    function hoverOn() {
        hover = true;
    }

    function hoverOff() {
        hover = false;
    }
</script>

<div class="card" class:visible={ hover } class:odd={ object.id % 2 === 1 }
    role="presentation"
    on:mouseenter={ hoverOn }
    on:mouseleave={ hoverOff }
    on:focusin={ hoverOn }
    on:focusout={ hoverOff }
>
    <a href={object.slug}>
        <div>
            <h2 class="title">{object.title}</h2>
            <span class="description">{object.industry}</span>
        </div>
        <div class="arrow-wrapper">
            <div class="arrow-circle" class:visible={ hover }>
                <svg class="arrow-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.25 10.5079C4.25 10.2317 4.47386 10.0079 4.75 10.0079H16.75C17.0261 10.0079 17.25 10.2317 17.25 10.5079V13.5079C17.25 13.784 17.0261 14.0079 16.75 14.0079H4.75C4.47386 14.0079 4.25 13.784 4.25 13.5079V10.5079Z" fill="currentColor"/>
                    <path d="M11.5576 3.86141C11.7528 3.66615 12.0694 3.66615 12.2647 3.86141L20.0429 11.6396C20.2381 11.8348 20.2381 12.1514 20.0429 12.3467L17.9215 14.468C17.7263 14.6633 17.4097 14.6633 17.2144 14.468L9.43626 6.68984C9.241 6.49458 9.241 6.17799 9.43626 5.98273L11.5576 3.86141Z" fill="currentColor"/>
                    <path d="M20.0429 11.6543C20.2382 11.8496 20.2382 12.1662 20.0429 12.3614L12.2648 20.1396C12.0695 20.3348 11.7529 20.3348 11.5576 20.1396L9.43633 18.0183C9.24107 17.823 9.24107 17.5064 9.43633 17.3112L17.2145 9.53299C17.4098 9.33772 17.7263 9.33772 17.9216 9.53299L20.0429 11.6543Z" fill="currentColor"/>
                </svg>
            </div>
        </div>
        <div class="card-overlay" class:visible={ hover } class:even={ object.id % 2 === 0 }></div>
        <div class="parallax">
            <img class="image" class:blur={ hover } src={ object.thumbnail } alt={object.title}>
        </div>
    </a>
</div>

<style>
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: end;
        width: 100%;
        height: 100%;
        padding: var(--size-11);
        aspect-ratio: 1 / 1.2;
        overflow: hidden;
        background-color: wheat;
        border-radius: var(--size-11);
        outline: 0 solid transparent;
    }

    .card.visible {
        outline: var(--size-3) solid var(--neutral-900);
    }

    .card.odd {
        justify-content: start;
    }

    a {
        justify-content: start;
        color: var(--neutral-900);
    }

    .arrow-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        transition: 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    }

    .arrow-circle {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 0;
        height: 0;
        color: var(--neutral-100);
        background-color: var(--neutral-900);
        border-radius: 100%;
        overflow: hidden;
    }

    .arrow-circle.visible {
        width: var(--size-15);
        height: var(--size-15);
    }

    .arrow-icon {
        width: var(--size-13);
        height: var(--size-13);
    }

    .title {
        position: relative;
        z-index: 3;
        margin-bottom: var(--size-5);
        font-size: var(--size-10);
        filter: drop-shadow(0 0 var(--size-2) var(--neutral-100));
    }

    .description {
        position: relative;
        z-index: 3;
        font-size: var(--size-9);
        font-weight: 500;
        line-height: 140%;
    }

    .card-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        opacity: 70%;
        background: linear-gradient(
            -15deg, 
            rgba(var(--neutral-gradient), 0.1) 55%,
            rgba(var(--neutral-gradient), 0.8) 80%,
            rgba(var(--neutral-gradient), 1) 100%
        );
        transition: 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    }

    .card-overlay.even {
        background: linear-gradient(
            -165deg, 
            rgba(var(--neutral-gradient), 0.1) 55%,
            rgba(var(--neutral-gradient), 0.8) 80%,
            rgba(var(--neutral-gradient), 1) 100%
        );
    }

    .card-overlay.visible {
        opacity: 100%;
    }

    img {
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    .blur {
        filter: blur(var(--size-4));
    }

    @media only screen and (max-width: 979px) {
		.title {
            font-size: var(--size-9);
        }
	}

    @media only screen and (min-width: 480px) and (max-width: 599px) {
		.title {
            font-size: var(--size-8);
        }

        .description {
            font-size: var(--size-8);
        }
	}
</style>